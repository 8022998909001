import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
 
import { 
  getMenuList as getMenuListAction,
  update as updateAction, 
} from "../../redux/modules/menu";

import { convertImages, fromArrayListKey, inArrayValueByKey, toArrayListKey } from "../../utilities/array";
 
import UiButtonColor from "../../components/button/UiButtonColor"; 
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";


import Env from "../../services/Env";
import { storeData } from "../../services/Storage";
import UiTreeMenuTable from "../../components/table/UiTreeMenuTable";


import "./MenuStyle.scss";

function MenuListPage(props) {
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);

 
  useEffect(() => {
    props.getMenuList(); 
  }, []);

 
 

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Меню на сайте" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/menu/add");
            }}
          />
        </div>
      </section>

      

      <section className="page-content">
    
        <UiTreeMenuTable  
          list={props.menu.filteredList} 
          onEditClick={(item) => {
            storeData("editMenu", item);
            storeData("editMenuList", props.menu.list);
            navigate(`/edit/menu/${item.id}`);
          } } 
          onDeleteClick={(item) => {
            let _item = item;
            _item.is_del =  1;
            props.update(_item);
          } } 
          onChangeVal={(v, item) => { 
            let _item = item;
            if(v == "is_menu" ) _item.is_menu = _item.is_menu == 1 ? 0 : 1;
            if(v == "is_popular" ) _item.is_popular = _item.is_popular == 1 ? 0 : 1;
            if(v == "is_site_menu" ) _item.is_site_menu = _item.is_site_menu == 1 ? 0 : 1;
            if(v == "is_top_menu" ) _item.is_top_menu = _item.is_top_menu == 1 ? 0 : 1;
            if(v == "is_disable_url" ) _item.is_disable_url = _item.is_disable_url == 1 ? 0 : 1;
      
            props.update(_item);
          } } 

          onChangeVisible={(v, item) => {
            let _item = item;
            let f = _item.is_menu == 1 ? 0 : 1;
            _item.is_menu = f;
            _item.data = {
              id: v,
              is_menu: f
            }
            props.update(_item);
          } } 

          onChangeSort={(v, item) => {
            let _item = item;
            item.sort = v;
            props.update(_item);
          } } 
        />

      </section>

 


 
 
    </div>
  );
}

export default connect(
  ({  menu, category }) => ({
    category,
    menu,
  }),
  {
    getMenuList: getMenuListAction,
    update: updateAction, 
 
 
  }
)(MenuListPage);
