import React, { useState } from "react";

import "./UiInputText.scss";
import { inArrayValueByKey, fromArrayListKey } from "../../utilities/array";

const UiDropDown = (props) => {
  const [value, setValue] = useState("");

 
  const items = (props.items ? fromArrayListKey(  props.items, "title", props.sort)  : []).map((item, index) => {
 
    return (
      <option key={index} value={item}>
        {item}
      </option>
    );
  });


  return (
    <div className={"input-wrap" + (props.small2 ? " small2" : "")}>
      {props.label ? <label>{props.label}</label> : null}

      <select
        className={
          "input-text" +
          (props.disabled ? " disabled" : "") +
          (props.info ? " info-active" : "") +
          (props.small ? " small" : "") + 
          (props.small2 ? " small2" : "")
        }
       
       value={inArrayValueByKey(props.items, "id", props.value ) ? inArrayValueByKey(props.items, "id", props.value  ).title  : ""}
        onChange={(e) => {
          console.log(e.target.value)
          if (props.onChange) { 
            props.onChange(e.target.value);
 
          }
        }}
      >
        
        {props.showEmptyVal ? <option value={null}></option> : null}
        {items}
      </select>
   
      {props.info ? (
        <div className="input-text-info-wrap">
          <p className="input-text-info">{props.info}</p>
        </div>
      ) : null}
      {props.error && value === "" ? (
        <p className="input-text-warn">{props.errorText}</p>
      ) : null}
    </div>
  );
};

export default UiDropDown;
