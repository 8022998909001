
export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  var date = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);

  return `UTC ${date == "-00:00" ? "+00:00" : date}`
}

export const getCurrentTime = () => {
  let current_time = new Date();
  let hh = current_time.getHours();
  let mm = current_time.getMinutes();
  if (hh < 10) hh = '0' + hh;
  if (mm < 10) mm = '0' + mm;
  return [hh, mm].join(':');
}

export const check1DayBefore = (date ) => {
  var curr = new Date(); 
  curr.setDate(curr.getDate() - 1);
  if( new Date(date) > curr ) return true;
  return false;
}

export const check2Days = (date, delimetr = ".") => {
  var curr = new Date(),
    cMonth = (curr.getMonth() + 1),
    cDay = curr.getDate(),
    cYear = curr.getFullYear();

  if (date) {
    var d = new Date(date.replace(/-/g, "/")),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  } else {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (cDay == day && cMonth == month) return true;
  if (cDay == day+1 && cMonth == month) return true;
  return false;
}

export const formatDateDMYString = (date, delimetr = ".") => {
  var curr = new Date(),
    cMonth = (curr.getMonth() + 1),
    cDay = curr.getDate(),
    cYear = curr.getFullYear();

  if (date) {
    var d = new Date(date.replace(/-/g, "/")),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  } else {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  }



 
 

  if (parseInt(cDay) == parseInt(day) && parseInt(cMonth) == parseInt(month)) return "Сегодня";
  if (parseInt(cDay)+1 == parseInt(day) && parseInt(cMonth) == parseInt(month)) return "Завтра";



  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

    return [day, month, year].join(delimetr);
}

export const formatDateDMY = (date, delimetr = ".") => { 
  if (date) {
    var d = new Date(date.replace(/-/g, "/").replace(/T/g, " ") ),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  } else {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join(delimetr);
}

export const formatDateYMD = (date, delimetr = ".") => {

  if (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  } else {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join(delimetr);
}


export const formatDatePlusDayYMD = (date, _day, delimetr = ".") => {
  if (date) {
    var d = new Date(date);
    d.setDate(date.getDate() + _day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

  } else {
    var d = new Date();
    d.setDate(d.getDate() + day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join(delimetr);
}


export const formatDatePlusDayDMY = (date, _day, delimetr = ".") => {
  if (date) {
    var d = new Date(date);
    d.setDate(date.getDate() + _day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

  } else {
    var d = new Date();
    d.setDate(d.getDate() + day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join(delimetr);
}

export const formatDateHM = (date) => {
  var d = new Date(date),
    hours = d.getHours(),
    minutes = d.getMinutes();

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;

  return [hours, minutes].join(':');
}