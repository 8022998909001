import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";



import {
  getBlogLists as getBlogListsAction,
  update as updateAction,
} from "../../redux/modules/material";


import { convertImages, fromArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./MaterialsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function BlogListPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: false, title: "" },  
 
    { sort: true, title: "Название" }, 

    { sort: false, title: "Скрыть" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getBlogLists(page).then((data) => {
      if (data){ 
        setMaxPage(data.paginate.total_page_count)
      } 
    })
  }, []);

  useEffect(() => {
    let arr = [];
    props.material.list.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit", onClick: () => {
            storeData("edit", item)
            navigate("/blog/add");
          }
        },
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            let s = _item.sort;
            if(v == 1 ){
              s++;
              _item.sort = s;
            } else {
              s--;
              _item.sort = s;
            } 
            console.log(s)
            props.update(_item).then(() => props.getBlogLists()  );
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
 
 
        { text: item.title }, 

        {
          button: item.is_active == 0 ? "eye-open" : "eye", onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item);
          }
        },
        {
          button: "delete", onClick: () => {
            let _item = item;
            _item.is_del = 1;
            props.update(_item);
          }
        },
      ])
    })
    setTableListOrders(arr);
  }, [props.material.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список статей" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/blog/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
           pageSize={20}
           page={page}
           titleList={clientsTitles}
           grid={clientsTitles.length}
           tableList={tableListOrders} 
 
          
           onChange={(p) => setPage(p)}
           maxPage={maxPage} 
        />
      </section>

    </div>
  );
}

export default connect(
  ({ material }) => ({
    material,
  }),
  {
    getBlogLists: getBlogListsAction,
    update: updateAction,


  }
)(BlogListPage);
