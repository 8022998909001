import ENV from './Env.js';


export const getRootListByCat = (_id, _page) => {
  return fetch(`${ENV.API_URL}/storage/root/category/${_id}?page=${_page}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getListByCat = (_id) => {
  return fetch(`${ENV.API_URL}/storage/category/${_id}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getList = (page) => {
  return fetch(`${ENV.API_URL}/storage/all?page=${page}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}



export const getListByArray = ( _data) => { 
  return fetch(`${ENV.API_URL}/storage/show/array`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const makeStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/store`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/update/${_data['id']}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const getStorageComposition = (_data) => {
  return fetch(`${ENV.API_URL}/composition/storage/show/${_data.id}`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}

export const searchStorageLine = (_data ) => {
  return fetch(`${ENV.API_URL}/storage/search/0/${_data}`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}

export const getFiltersParams = () => {
  return fetch(`${ENV.API_URL}/storage/filters/list`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}
