import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDeliveryZonesList as getDeliveryZonesListAction,
  getDeliveryStoragesZonesList as getDeliveryStoragesZonesListAction,
  getDeliveryZonesRulesList as getDeliveryZonesRulesListAction,
  addDeliveryZone as addDeliveryZoneAction,
  updateZone as updateZoneAction,
} from "../../../redux/modules/settings";


import UiPageText from "../../../components/text/UiPageText";
import UiInputText from "../../../components/inputs/UiInputText";
import UiTableAdaptive from "../../../components/table/UiTableAdaptive";
import UiButtonColor from "../../../components/button/UiButtonColor";

import UiDropDown from "../../../components/inputs/UiDropDown";
import UiSwitch from "../../../components/switch/UiSwitch";

import { formatDateYMD, formatDatePlusDayYMD } from "../../../utilities/date";
import { convertImages, fromArrayListKey, inArrayValueByKey, toArrayListKey } from "../../../utilities/array";

import "../SettingStyle.scss";
import UiMap from "../../../components/map/UiMap";

function SettingPage(props) {
  const intervalTitles = [

    { sort: false, title: "Зона" },
    { sort: false, title: "Цена" },
    { sort: false, title: "Правило" }, 
    { sort: false, title: "Управление" },
  ];

  const [deliveryZoneObj, setDeliveryZoneObj] = useState({
    zone_id: null, 
    price: null,
    rule: null
  });
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [deliveryRules, setDeliveryRules] = useState([]);

  const [intervalList, setIntervalList] = useState([]);

  const [date, setDate] = useState(new Date());

  const [intervalTime, setIntervalTime] = useState("");
  const [intervalLimit, setIntervalLimit] = useState(0);

  useEffect(() => {
    props.getDeliveryZonesList().then((data) => setDeliveryZones(data));
    props.getDeliveryStoragesZonesList();
    props.getDeliveryZonesRulesList().then((data) => setDeliveryRules(data));
  }, [props.login.user]);


  useEffect(() => {
    let _data = [];
    props.settings.deliveryStoragesList.map((item, index) => {
      let listItem = [
        {
          statusInfo: inArrayValueByKey(props.settings.deliveryZonesList, "id", item.zone_id) ? inArrayValueByKey(props.settings.deliveryZonesList, "id", item.zone_id).title : " -- -- ",
          statusList: fromArrayListKey(props.settings.deliveryZonesList, "title"),
          onStatusChange: (val) => {
            let _item = item;
            _item.zone_id = props.settings.deliveryZonesList[val].id;
            props.updateZone(_item);
          },

        },

        {
          text: item.price,
          input: item.price == null ? ` ` : `${item.price}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.price = parseInt(val);
            props.updateZone(_item);
          },

        },



        {
          statusInfo: inArrayValueByKey(props.settings.deliveryZonesRules, "id", item.rule) ? inArrayValueByKey(props.settings.deliveryZonesRules, "id", item.rule).title : " -- -- ",
          statusList: fromArrayListKey(props.settings.deliveryZonesRules, "title"),
          onStatusChange: (val) => {
            let _item = item;
            _item.rule = props.settings.deliveryZonesRules[val].id;
            props.updateZone(_item);
          },

        },

        
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.updateZone(_item);
            }
          },
        },
      ];
      _data.push(listItem)
    })
    setIntervalList(_data)

  }, [props.settings.deliveryStoragesList, props.settings.deliveryZonesList, props.settings.deliveryZonesRules]);


  /**
 *
 * @param {string} key
 * @param {any} val
 */
  function updateDZObj(key, val) {
    let _obj = deliveryZoneObj;
    _obj[key] = val;
    setDeliveryZoneObj({ ..._obj });
  }


  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройка стоимости доставки" />
        </div>
      </section>
      <section className="page-content">

        <UiMap />

        <UiTableAdaptive
            pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList}
        />
        <br />
        <p>Добавить интервала</p>


        <UiDropDown
          label="Зона:"
          items={deliveryZones}
          value={deliveryZoneObj.zone_id}
          onChange={(val) => {
            updateDZObj(
              "zone_id",
              inArrayValueByKey(deliveryZones, "title", val)?.id
            );
          }}
        />
        <UiInputText
          label={`Цена`}
          type={'number'}
          value={deliveryZoneObj.price}
          onChange={(val) => updateDZObj(
            "price",
            parseInt(val)
          )}
        />
        <UiDropDown
          label="Правило:"
          items={deliveryRules}
          value={deliveryZoneObj.rule}
          onChange={(val) => {
            updateDZObj(
              "rule",
              inArrayValueByKey(deliveryRules, "title", val)?.id
            );
          }}
        />
        <UiButtonColor
          color="indigo"
          text="Добавить зону"
          small={true}
          onClick={() => { 
            if (deliveryZoneObj.zone_id != null  && deliveryZoneObj.rule != null ) {
              props
                .addDeliveryZone(deliveryZoneObj);
            } else {
              alert("Выберете зону и правило");
            }
          }}
        />
      </section>






    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getDeliveryZonesList: getDeliveryZonesListAction,
  getDeliveryStoragesZonesList: getDeliveryStoragesZonesListAction,
  getDeliveryZonesRulesList: getDeliveryZonesRulesListAction,
  addDeliveryZone: addDeliveryZoneAction,
  updateZone: updateZoneAction
})(SettingPage);
