import ENV from './Env.js';


export const getBlogList = (_page = 1) => {

  return fetch(`${ENV.API_URL}/material/blog/all?page=${_page}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getList = () => {

  return fetch(ENV.API_URL + '/material/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const makeMaterial = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(ENV.API_URL + '/material/store', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateMaterial = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/material/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

