import React, { useEffect, useState } from "react";
import { inArray } from "../../utilities/array";

import UiCheckItem from "../../components/check/UiCheckItem";
 

const UiExpandebleList = (props) => {
  const [expandBtn, setExpandBtn] = useState(false);

  useEffect(() => {

    props.item.value.map((filItem, filIndex) => { 
      props.filterValues.map((fItem) => { 
        if( inArray( fItem.value , filItem)) setExpandBtn(true);
      }); 
    })

  }, [ props.item.value]);

  let filtersItem = props.item.value.map((filItem, filIndex) => {
    let flag = false;

    props.filterValues.map((fItem) => { 
      if(!flag) flag =  inArray( fItem.value , filItem);
    })

   
    return (
      <UiCheckItem
        key={filIndex}
        label={filItem}
        defaultValue={flag}
        onChange={(val) => {
          props.updateFilter(props.item.key, filItem);
        }}
      />
    );
  });
 
  return (
    <div style={{margin: "10px"}}>
      <h4 style={{cursor: "pointer"}} onClick={() => {
        setExpandBtn(!expandBtn)
      }}>{!expandBtn ? <span>➕</span> : <span>➖</span> } {props.item.title}</h4>
      <div className="product-add-info-checkboxes">{expandBtn ?filtersItem : null}</div>
    </div>
  );

};

export default UiExpandebleList;
