import React from "react";

import "./UiButtonColor.scss";

const UiButtonColor = (props) => {
  const styles = {};

  return (
    <div className="button-wrap">
      <button
        className={
          "button" +
          (props.color ? " button-" + props.color : "") +
          (props.radius ? " radius" : "") +
          (props.small ? " small" : "")
        }
        onClick={props.onClick}
        style={styles}
      >
        <span>{props.text}</span>
      </button>
    </div>
  );
};

export default UiButtonColor;
