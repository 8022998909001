import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDeliveryZonesRulesList as getDeliveryZonesRulesListAction,
  addRuleZone as addRuleZoneAction,
  updateRule as updateRuleAction,
} from "../../../redux/modules/settings";


import UiPageText from "../../../components/text/UiPageText";
import UiInputText from "../../../components/inputs/UiInputText";
import UiTableAdaptive from "../../../components/table/UiTableAdaptive";
import UiButtonColor from "../../../components/button/UiButtonColor";

 
import "../SettingStyle.scss";

function SettingPage(props) {
  const intervalTitles = [

    { sort: false, title: "Правило" },
    { sort: false, title: "Мин" },
    { sort: false, title: "Макс" },
    { sort: false, title: "Управление" },
  ];

  const [deliveryZoneObj, setDeliveryZoneObj] = useState({
    title: null,
    min: 0,
    max: 0
  });

  const [deliveryRules, setDeliveryRules] = useState([]);

  const [intervalList, setIntervalList] = useState([]);

  const [date, setDate] = useState(new Date());

  const [intervalTime, setIntervalTime] = useState("");
  const [intervalLimit, setIntervalLimit] = useState(0);

  useEffect(() => {
    props.getDeliveryZonesRulesList();
  }, [props.login.user]);


  useEffect(() => {
    let _data = [];
    props.settings.deliveryZonesRules.map((item, index) => {

      let listItem = [
        {
          text: item.title,
          input: item.title == null ? ` ` : `${item.title}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.title = (val);
            props.updateRule(_item);
          },

        },

        {
          text: item.min,
          input: item.min == null ? ` ` : `${item.min}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.min = parseInt(val);
            props.updateRule(_item);
          },

        },

        {
          text: item.max,
          input: item.max == null ? ` ` : `${item.max}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.price = parseInt(val);
            props.updateRule(_item);
          },

        },


        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.updateRule(_item);
            }
          },
        },
      ];
      _data.push(listItem)
    })
    setIntervalList(_data)

  }, [ props.settings.deliveryZonesRules ]);


  /**
 *
 * @param {string} key
 * @param {any} val
 */
  function updateDZObj(key, val) {
    let _obj = deliveryZoneObj;
    _obj[key] = val;
    setDeliveryZoneObj({ ..._obj });
  }


  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройка правил для доставки" />
        </div>
      </section>
      <section className="page-content">

        <UiTableAdaptive
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList}
        />
        <br />
        <p>Добавить интервала</p>



        <UiInputText
          label={`Название`}

          value={deliveryZoneObj.title}
          onChange={(val) => updateDZObj(
            "title",
             (val)
          )}
        />

        <UiInputText
          label={`Цена мин`}
          type={'number'}
          value={deliveryZoneObj.min}
          onChange={(val) => updateDZObj(
            "min",
            parseInt(val)
          )}
        />

        <UiInputText
          label={`Цена макс`}
          type={'number'}
          value={deliveryZoneObj.max}
          onChange={(val) => updateDZObj(
            "max",
            parseInt(val)
          )}
        />

        <UiButtonColor
          color="indigo"
          text="Добавить правило"
          small={true}
          onClick={() => {
            if (deliveryZoneObj.title != null ) {
              props
                .addRuleZone(deliveryZoneObj);
            } else {
              alert("Укажите название");
            }
          }}
        />
      </section>






    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getDeliveryZonesRulesList: getDeliveryZonesRulesListAction,

  addRuleZone: addRuleZoneAction,
  updateRule: updateRuleAction
})(SettingPage);
