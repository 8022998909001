import OrderConstant from "../constants/OrderConstant";

export const getInfo = (item) => {
    const volumeArray = OrderConstant.VOLUME_ARRAY;
    const trashArray = OrderConstant.TRASH_ARRAY
    const takArray = OrderConstant.TAK_ARRAY;
    const unbuildArray = OrderConstant.UNBUILD_ARRAY;
    const packArray = OrderConstant.PACK_ARRAY;
    const liftTypes = OrderConstant.LIFT_TYPES;
    
    const prr_types =  OrderConstant.PRR_TYPE;
    let arr = [];
    arr.push({ text: volumeArray[item.volume_car].text });
    arr.push( { text: `${item.movers} грузчика` });

    arr.push({ text: prr_types[item.prr_types].text });
    if(item.moving_types != 4) {
        if(item.is_hard_work) arr.push({ text: takArray[item.tak_value].text });
        if(item.is_assembly_disassembly)arr.push({ text: unbuildArray[item.unbuild_value].text });
        if(item.additional_work)arr.push({ text: packArray[item.pack_value].text });
    } else {
        arr.push({ text: trashArray[item.trash_value].text });
    }
    
    return arr;
}


export const getTotalPrice = (_arr, _arrSpecial, _delivery, _discount, _onlyArr = false) => {
    let sum = 0;
    _arr.map((item, index) => { 
        if(item[4]) sum = sum +  parseInt(item[4].text.replace(" ₽", "")) * 1;
    }) 
    _arrSpecial.map((item, index) => { 
        if(item[4]) sum = sum +  parseInt(item[4].text.replace(" ₽", "")) * parseInt(item[3].text);
    }) 
    if(!_onlyArr){ 
        sum = sum - sum * parseFloat(_discount/100);
        sum = sum + parseFloat(_delivery); 
    } 
    return Math.round(sum);
}

export const getSalePrice = (item, c) => {
    let sum = 0;

    sum = sum + item.price * (c);
    /*
    if(item.sale == 0) { 
        sum = sum + item.price * (c);
    } else { 
        sum = sum + ( c >= item.sale_count ? item.sale : item.price) * (c);
    }
    */
    return Math.round(sum);
}


export const reorderBasket = (_arr) => {
    let arr = [];
    _arr.map((item) => {
       let f= -1;
       arr.map((item2, index2) => {
        if(item.title == item2.title) f = index2;
       })
       if(f == -1){
        item.count= 1;
        arr.push(item)
       } else {
        arr[f].count++;
       }
    })
    return arr;
}

export const resortOrderComposition = (_arr, _composition) => {
    console.log(_composition)
    let comp = _composition.split(",");
    let arr = [];
    let basket = []; 

    _arr.map((item, index) => { 
        comp.map((item2) => {
            if(item.id == item2) basket.push(item);
        })
    }) 

    basket.map((item) => {
        let f= -1;
        arr.map((item2, index2) => {
         if(item.title == item2.title) f = index2;
        })
        if(f == -1){
         item.count= 1;
         if(!item.images) item.images = item.imgs.split(",");
         arr.push(item)
        } else {
         arr[f].count++;
        }
     })

     return arr;
}


export const getOrderCompositionString  = (_arr) => {
    let list = [];
    if(Array.isArray( _arr)){
        _arr.map((item) => {
            if(item.id != 1 && item.id != 7 && item.title) list.push(item.title);
            if(item.custom_title) list.push(`${item.custom_title}`);
        })
    }
    
    list = [ ...new Set(list) ];
    return list.join(" ,");
}
