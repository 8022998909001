import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputText from "../../components/inputs/UiInputText";
import UiHeaderBg from "../../components/header/UiHeaderBg";
import UiText from "../../components/text/UiText";
import UiTextTitle from "../../components/text/UiTextTitle";

import { loginUser as loginUserAction } from "../../redux/modules/login";

import "./LoginStyle.scss";

function LoginPage(props) {
  const navigate = useNavigate();
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);

  useEffect(() => {
    if (props.login.user) {
      navigate("../", { replace: true });
    }
  }, [props.login.user]);

  function login() {
    if (email && pass) {
      props.loginUser(email, pass);
    }
  }

  return (
    <div id="page" className="login-page">
      <div className="form">
        <div className="form-inner">
          <div className="login-title-wrap">
            <UiTextTitle
              adaptive
              color="brand"
              size={32}
              text="Добро пожаловать"
            />
            <UiText adaptive color="dark" size="f22">
              Вход в панель управления
            </UiText>
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setEmail(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Email"
              type="text"
              name="username"
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setPass(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Пароль"
              type="password"
              name="password"
            />
          </div>
          <div className="login-button-wrap">
            <UiButtonColor
              color="brand"
              error={email == "" || pass == ""}
              errorText="Заполните необходимые поля"
              onClick={() => login()}
              text="Войти"
            />
          </div>
        </div>
      </div>
      <UiHeaderBg keyboardActive={keyboardActive} />
    </div>
  );
}

export default connect(({ login }) => ({ login }), {
  loginUser: loginUserAction,
})(LoginPage);
