import React from "react";

import "./UiCardAvatar.scss";

const UiCardAvatar = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: props.size,
        height: props.size,
      }}
      className={"card-avatar"}
    >
      <span>{props.avatarText}</span>
    </div>
  );
};

export default UiCardAvatar;
