import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getUserReviewsList as getUserReviewsListAction, 
  updateUserReview as updateUserReviewAction,
} from "../../redux/modules/users";

import { convertImages, fromArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./MaterialsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";
import { formatDateDMY } from "../../utilities/date";

function ReviewPage(props) { 
  const navigate = useNavigate(); 
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [

    { sort: true, title: "Телефон" },
    { sort: false, title: "Имя" },
    { sort: false, title: "Отзыв" },
    { sort: false, title: "Дата" }, 
    { sort: false, title: "Опубликовать" },

  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    getList();
  }, []);

 function getList(){
  props.getUserReviewsList().then((data) => {
    let arr = [];
    data.map((item) => {
     
      arr.push([
       
        { text: `+${item.user_phone}` },
        { text: item.user_name },
        { text: item.text },
        { text: formatDateDMY(item.created_at)  },
        {
          button: item.is_compleat == 1 ? "eye-open" : "eye",
          color:  item.is_compleat == 1 ? "green" :  "red" ,
          onClick: () => {
            let _item = item;
            _item.is_compleat = item.is_compleat == 1 ? 0 : 1;
            props.updateUserReview(_item).then(() =>  getList() );
            
          },
        },
      ]);
    });
    setTableListOrders(arr);
  })
 }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список отзывов" />
        </div>
      
      </section>
      <section className="page-content">
 

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
    </div>
  );
}

export default connect(
  ({ users }) => ({
    users,
  }),
  {
    getUserReviewsList: getUserReviewsListAction,
    updateUserReview: updateUserReviewAction,
  }
)(ReviewPage);
