import ENV from '../services/Env.js';




export const checkLogin = (log, pass) => {

  return fetch(ENV.API_URL + '/manager/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: pass,
      email: log,
    }),
  }).then((response) => {
    return response.json()
  });
}

export const makeUser = (_phone) => {
  return fetch(`${ENV.API_URL}/users/store`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      phone: _phone, 
    }),
  }).then((response) => {
    return response.json()
  });
}


export const getUsers = (_api) => {

  return fetch(ENV.API_URL + '/users/all', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api
    }),
  }).then((response) => {
    return response.json()
  });
}

export const filter = (_api, _key, _value) => {

  return fetch(ENV.API_URL + '/users/filter', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      filter_key: _key,
      filter_value: _value
    }),
  }).then((response) => {
    return response.json()
  });
}



export const addWorkerUser = (_data) => {
  return fetch(`${ENV.API_URL}/users/worker/store`, {
      method: 'POST',
      headers: ENV.getHeaders(),
      body: JSON.stringify(_data)
  }).then(function (response) {
      return response.json();
  });

}
 
export const updateWorkerUser = (_data) => {
  return fetch(`${ENV.API_URL}/users/worker/update/${_data.id}`, {
      method: 'POST',
      headers: ENV.getHeaders(),
      body: JSON.stringify(_data)
  }).then(function (response) {
      return response.json();
  });

}
 




export const getUser = (_api, _id, _ni = false) => {
  let _data = {
    api_token: _api,
    id: _id,
  }
  if(_ni){
    _data = {
      api_token: _api, 
      no_ip: true
    }
  }
  return fetch(`${ENV.API_URL}/users/show/${_id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data),
  }).then((response) => {
    return response.json()
  });
}

export const getUserNotification = (_api, _id) => {

  return fetch(ENV.API_URL + '/users/notifications/list', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      uid: _id,
    }),
  }).then((response) => {
    return response.json()
  });
}


export const getUserRating = (_api) => {

  return fetch(ENV.API_URL + '/users/rating/top', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
    }),
  }).then((response) => {
    return response.json()
  });
}


export const readUserNotification = (_api, _id) => {

  return fetch(ENV.API_URL + '/users/notifications/read', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      uid: _id,
    }),
  }).then((response) => {
    return response.json()
  });
}




export const regUser = (_email) => {
  return fetch(ENV.API_URL + '/users/register', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: _email,


    })
  }).then(function (response) {
    return response.json();
  });
}

export const confirmUser = (_token) => {
  return fetch(ENV.API_URL + '/users/confirm', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: _token,
    })
  }).then(function (response) {
    return response.json();
  });
}


export const editUser = (_data) => {

  return fetch(`${ENV.API_URL}/users/update/${_data.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}



export const addNotification = (_api, _type, _value, _text) => {
  return fetch(ENV.API_URL + '/users/notifications/add', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      type: _type,
      value: _value,
      text: _text
    })
  }).then(function (response) {
    return response.json();
  });
}


export const getTable = (_api, _name) => {
  return fetch(ENV.API_URL + '/get/table', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      table: _name
    })
  }).then(function (response) {
    return response.json();
  });
}

export const editTable = (_api, _table, _item_title, _item, _value_title, _value ) => {
  return fetch(ENV.API_URL + '/edit/table', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _api,
      table: _table,
      item_title:  _item_title, 
      item: _item,
      value_title: _value_title,
      value: _value
    })
  }).then(function (response) {
    return response.json();
  });
}

export const insertTable = (_data) => {
  return fetch(ENV.API_URL + '/insert/table', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

 
export const getUserReviews = (_data) => {
  return fetch(`${ENV.API_URL}/rates/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }, 
  }).then(function (response) {
    return response.json();
  });
}


export const updateReview = (_data) => {
  return fetch(`${ENV.API_URL}/rates/update/${_data.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}
