import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const menuHeight = height - 64;
  if (document.getElementById("app")) {
    //document.getElementById("app").setAttribute("style", `width:${width}px`);
    //document.getElementById("app").setAttribute("style", `height:${height}px`);
  }
  if (document.getElementById("menu")) {
    //document.getElementById("menu").setAttribute("style", `height:${menuHeight}px`);
  }
}

export default function useWindowDimensions(func) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      func();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
