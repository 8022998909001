import React, { useEffect, useState } from "react";

import UiPaginator from "../bars/UiPaginator";
import UiTableAdaptiveCard from "./UiTableAdaptiveCard";

import "./UiTableAdaptive.scss";

const UiTableAdaptive = (props) => {
  const [filter, setFilter] = useState(null);
  const [pageCount, setPageCount] = useState(11);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = props.pageSize ? props.pageSize : 20;

 
  
  useEffect(() => {
    if (props.maxPage)
      setPageCount( props.maxPage );
  }, [props.maxPage]);


  useEffect(() => {
    if (props.tableList && !props.maxPage)
      setPageCount(paginateCount(search(sort(props.tableList))).length);
  }, [props.tableList, search(sort(props.tableList))]);

  useEffect(() => {
    if (props.tablePromoList)
      setPageCount(paginateCount(search(sort(props.tablePromoList))).length);
  }, [props.tablePromoList, search(sort(props.tablePromoList))]);

  useEffect(() => {
    if (props.invitesList)
      setPageCount(paginateCount(search(sort(props.invitesList))).length);
  }, [props.invitesList, search(sort(props.invitesList))]);

  function search(_arr) {
    if (_arr) {
      if (props.search) {
        let arr = [];

        _arr.map((item) => {
          let keys = Object.keys(item);
          let f = false;
          keys.map((key) => {
            let ss = item[key].text + "_";
            if (ss.toLocaleLowerCase().includes(props.search.toLocaleLowerCase())) {
              f = true;
            }
          });
          if (f) arr.push(item);
        });
        return arr;
      } else {
        return _arr;
      }
    } else {
      return [];
    }
  }

  function sort(arr) {
    if (arr) {
      if (filter == null) {
        return arr;
      } else {
        if (arr.length > 0) {
          let keys = Object.keys(arr[0]);
          let f = filter;
          if (f === "-0") {
            f = Math.abs(f);
            return arr.sort(function (b, a) {
              var keyA = a[f].text,
                keyB = b[f].text;
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
          } else if (f == 0) {
            f = Math.abs(f);

            return arr.sort(function (a, b) {
              var keyA = a[f].text,
                keyB = b[f].text;
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
          } else {
            if (f < 0) {
              f = Math.abs(f);
              return arr.sort(function (b, a) {
                var keyA = a[f].text,
                  keyB = b[f].text;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
            } else {
              f = Math.abs(f);
              return arr.sort(function (a, b) {
                var keyA = a[f].text,
                  keyB = b[f].text;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
            }
          }
        } else {
          return arr;
        }
      }
    } else {
      return [];
    }
  }

  function paginateCount(arr) {
    if (arr) {
      let _pages = [];
      arr.map((item, index) => {
        if (index % pageSize == 0) _pages.push(index);
      });
      return _pages;
    } else {
      return [];
    }
  }

  function paginate(arr) {
    if(props.page){
      return arr
    } else {
      let _arr = [];
      arr.map((item, index) => {
        if (
          index >= currentPage * pageSize &&
          index < currentPage * pageSize + pageSize
        ) {
          _arr.push(item);
        }
      });
  
      return _arr;
    }
   
  }

  if (props.tableList) {
    if (props.tableList.length > 0) {
      var rows = paginate(search(sort(props.tableList))).map((item, index) => {
        return (
          <UiTableAdaptiveCard
            key={index}
            adaptive={props.adaptive}
            color={item[0].trColor}
            colored={props.colored}
            cols={item}
            grid={props.grid}
            titles={props.titleList}
          />
        );
      });
    }
  }

  return (
    <div className="table-adaptive">
      <table className="table">
        <UiTableAdaptiveCard
          adaptive={props.adaptive}
          colored={props.colored}
          cols={props.titleList}
          grid={props.grid}
          onFilter={(val) => setFilter(val)}
          isTitle={true}
          titles={props.titleList}
        />
        {rows ? rows : <UiTableAdaptiveCard adaptive={props.adaptive} colored={props.colored} isEmpty={true} />}
      </table>
      {pageCount > 1 ? (
        <UiPaginator
          currentPage={currentPage}
          lastPage={pageCount}
          onChange={(page) => {
            setCurrentPage(page);
            if(props.onChange) props.onChange(page + 1);
            document.getElementsByClassName("home-page")[0].scrollIntoView(true)
          }}
        />
      ) : null}
    </div>
  );
};

export default UiTableAdaptive;
