import React, { useEffect, useState } from "react";

import UiDropDown from "../inputs/UiDropDown";

import "./UiTreeTable.scss";
import UiIconCategory from "../icons/icon-category";

const UiTreeItem = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div className="tree-table-adaptive-item">
      <div className="tree-table-adaptive-item-title">
        <p 
          onClick={() =>  { 
            setIsExpand(!isExpand);
            document.querySelector(`.lvl-m-${props.expand}`)?.classList.toggle('hide'); 
            document.querySelector(`.lvl-${props.expand}`)?.classList.toggle('hide');
            document.querySelector(`.lvl-${props.expand}`)?.classList.toggle('hide');
          }}
        >{props.expand ? <b> {isExpand ? '+' : '-'} </b> : ""}{props.title}</p>
        <div className="tree-table-adaptive-item-title-control">
          {props.icon && <UiIconCategory icon={props.icon} size={30} /> }
          <button onClick={() => props.onEditClick()}>✏️</button>
          {/*
          <button className={props?.is_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_menu")} >верх меню</button>
          <button className={props?.is_site_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_site_menu")} >главная</button>
          <button className={props?.is_popular == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_popular")}   >избранное</button>
          */}
          <UiDropDown items={props.subList} root={props.root} small2 />
        </div>
      </div>
      {props.list && (
        <div className="tree-table-adaptive-item-list">{props.list}</div>
      )}
    </div>
  );
};

 
const UiTreeTable = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.list) setList(props.list);
  }, [props.list]);

  var treeList = list.map((item, index) => {
    let list1 = item.list.map((item2, index2) => {
      let list2 = item2.list.map((item3, index3) => {
        let list3 = item3.list.map((item4, index4) => {
          return (
            <div key={index4} className="tree-table-adaptive-wrap lvl4">
              <UiTreeItem 
                icon={item4.icon}
                title={item4.title} 
                root={item4.root_category_id} 

                is_popular={item4.is_popular}
                is_menu={item4.is_menu}
                is_site_menu={item4.is_site_menu}


                subList={props.subList} 
                expand={`lv4${index2}`} 
                onEditClick={() => props.onEditClick(item4) } 
                onChangeVal={(v) => props.onChangeVal(v, item4) } 
              />
            </div>
          );
        });

        return (
          <div key={index3} className={`tree-table-adaptive-wrap lvl3 lvl-lv3${index}${index2}${index3}`}>
            <UiTreeItem 
                icon={item3.icon}
              title={item3.title} 
              list={list3} 
              root={item3.root_category_id}  

              is_popular={item3.is_popular}
              is_menu={item3.is_menu}
              is_site_menu={item3.is_site_menu}

              subList={props.subList} 
              expand={`lv3${index}${index2}${index3}`} 
              onEditClick={() => props.onEditClick(item3) } 
              onChangeVal={(v) => props.onChangeVal(v, item3) } 
              />
          </div>
        );
      });

      return (
        <div key={index2} className={`tree-table-adaptive-wrap lvl2 lvl-lv2${index}${index2}`}>
          <UiTreeItem 
             icon={item2.icon}
            title={item2.title} 

            is_popular={item2.is_popular}
            is_menu={item2.is_menu}
            is_site_menu={item2.is_site_menu}

            list={list2} 
            root={item2.root_category_id}  
            subList={props.subList} 
            expand={`lv2${index}${index2}`}
            onEditClick={() => props.onEditClick(item2) }
            onChangeVal={(v) => props.onChangeVal(v, item2) } 
          />
        </div>
      );
    });
    return (
      <div key={index} className={`tree-table-adaptive-wrap lvl1 lvl-m-${index}`}>
        <UiTreeItem 
               icon={item.icon}
          title={item.title} 
          
          is_popular={item.is_popular}
          is_menu={item.is_menu}
          is_site_menu={item.is_site_menu}

          list={list1} 
          root={item.root_category_id}  
          subList={props.subList} 
          expand={index} 
          onEditClick={() => props.onEditClick(item) } 
          onChangeVal={(v) => props.onChangeVal(v, item) } 
        />
      </div>
    );
  });

  return <div className="tree-table-adaptive">{treeList}</div>;
};

export default UiTreeTable;
