import React, { Component } from "react";

import "./UiCheckItem.scss";

import { ReactComponent as SvgIcon } from "../../assets/images/ui/check-white.svg";

class UiCheckItem extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    radioActive: false,
  };

  componentDidMount() {
    if (this.props.defaultValue !== undefined) {
      this.setState({ radioActive: this.props.defaultValue });
    }
  }

  radioCheck() {
    this.setState({ radioActive: !this.state.radioActive }, () =>
      this.props.onChange(this.state.radioActive)
    );
  }

  render() {
    return (
      <button className="check-item" onClick={() => this.radioCheck()}>
        <div
          className={
            "check-item-icon" + (this.state.radioActive ? " active" : "")
          }
        >
          <SvgIcon className="check-item-icon-check" />
        </div>
        <span>{this.props.label}</span>
      </button>
    );
  }
}
export default UiCheckItem;
